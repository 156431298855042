import * as color from "../../constants/color";
import * as inputSizes from "../../constants/inputSizes";
import * as routeHelpers from "../../lib/routeHelpers";
import * as texchangePropTypes from "../../texchangePropTypes";
import SearchInput from "../inputs/SearchInput";
import Dropdown from "../inputs/Dropdown";
import PageTitle from "../text/PageTitle";
import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import Tabs from "../Tabs";
import Infinite from "../Infinite";
import * as quotesApi from "../../lib/api/quotes";
import * as dataApi from "../../lib/api/data";


const styles = StyleSheet.create({
    content: {
        paddingRight: 10,
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    },
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `8px solid ${color.mediumRed}`,
        marginBottom: 20,
    },
    titleSection: {
        heigth: 64,
        backgroundColor: color.white,
    },
    searchBox: {
        maxWidth: 480,
        textAlign: "center",
        margin: "30px auto 30px auto",
    },
    table: {
        width: "100%",
        marginTop: 10,
        marginBottom: 20,
        borderCollapse: "collapse",
    },
    tableHeader: {
        fontSize: 13,
        color: color.borderBackgroundGray,
        padding: 10,
        whiteSpace: "nowrap",
        borderBottom: `1px solid ${color.mediumGray}`,
    },
    tableRow: {
        fontSize: 14,
        color: color.tableText,
        padding: "20px 10px",
        borderBottom: `1px solid ${color.tableBorder}`,
    },
    right: {
        float: "right",
        position: "relative",
        top: -38,
        right: 20,
        fontSize: 14,
        color: color.tableText,
    },
    noResults: {
        backgroundColor: color.white,
        color: color.darkGray,
        padding: 20,
        marginBottom: 40,
    },
    filterText: {
        display: "inline-block",
        fontSize: 14,
        marginLeft: 16,
        lineHeight: "28px",
    },
    dropdownHolder: {
        display: "inline-block",
    },
    dropdown: {
        display: "inline-block",
        width: 160,
        marginLeft: 16,
    },
    columnFilterContainer: {
        paddingTop: 12,
        paddingBottom: 12,
        backgroundColor: color.lightGray4,
        borderTop: `1px solid ${color.lightGray2}`,
        borderBottom: `1px solid ${color.lightGray2}`,
    },
    resetButtonHolder: {
        display: "inline-block",
        paddingLeft: 15,
    },
    resetButton: {
        color: color.white,
        backgroundColor: color.blue1,
        height: 48,
        padding: "0 15px",
        border: `1px solid ${color.mediumGray}`,
        borderRadius: 4,
        cursor: "pointer",
    },
});

const strings = {
    title: "Find a Quote",
    searchPlaceholder: "Search by Group or Broker",
    statePlaceHolder: "All states",
    effectivePlaceHolder: "All effectives",
    yearPlaceHolder: "All years",
    quoterPlaceHolder: "All quoters",
    repPlaceHolder: "All sales reps",
    statusPlaceHolder: "All statuses",
};

const inputNames = {
    search: "search",
};

export default class QuotesScreen extends Component {
    static propTypes = {
        location: texchangePropTypes.location.isRequired,
        history: texchangePropTypes.history.isRequired,
        me: texchangePropTypes.me.isRequired,
    };

    state = {
        allQuotes: [],
        allQuotesCount: 0,
        allQuotesMore: false,
        myQuotes: [],
        myQuotesCount: 0,
        myQuotesMore: false,
        testQuotes: [],
        testQuotesCount: 0,
        testQuotesMore: false,
        inputs: {
            [inputNames.search]: "",
        },
        loading: false,
        errors: {},
        columnLabels: ["Quote", "Group", "County", "Eff. Date", "Quoted On", "Quoted By", "Census", "Latest Prop", "Sales Rep", "Broker", "Status", "[LOC] & Carriers"],
        columnValues: ["quoteNumber", "groupName", "county", "effectiveDateString", "createDateString", "createdBy", "censusSize", "latestProposal", "salesRepName", "brokerName", "quoteStatus", "description"],
        selectedState: "-1",
        selectedEffective: "-1",
        selectedYear: "-1",
        selectedQuoter: "-1",
        selectedRep: "-1",
        selectedStatus: "-1",
        states: [],
        effectives: [
            { id: "1", name: "January effectives" },
            { id: "2", name: "February effectives" },
            { id: "3", name: "March effectives" },
            { id: "4", name: "April effectives" },
            { id: "5", name: "May effectives" },
            { id: "6", name: "June effectives" },
            { id: "7", name: "July effectives" },
            { id: "8", name: "August effectives" },
            { id: "9", name: "September effectives" },
            { id: "10", name: "October effectives" },
            { id: "11", name: "November effectives" },
            { id: "12", name: "December effectives" },
        ],
        years: [],
        quoters: [],
        reps: [],
        statuses: [
            { name: "Stage 1" },
            { name: "Stage 2" },
            { name: "Stage 3" },
            { name: "Sold" },
            { name: "Lost" },
            { name: "Failed" },
        ],
    };

    componentDidMount() {
        this._mounted = true;
        //years setup
        let years = [];
        let dt = new Date();
        let year = dt.getFullYear();
        const month = dt.getMonth();
        if (month >= 9) {
            years.push((year + 1).toString());
        }
        years.push(year.toString());
        years.push((year - 1).toString());
        years.push((year - 2).toString());
        years.push((year - 3).toString());

        this.setState({ loading: true, years: years });
        //Check if there are any params
        if (this.props.location && this.props.location.search) {
            const params = new URLSearchParams(this.props.location.search);
            let eff = params.get("eff");
            if (eff && eff.length > 0 && eff.toLowerCase() !== "null" && eff !== "-1") {
                if (!Number.isInteger(eff)) {
                    eff = eff === "JAN" ? "1" : eff === "FEB" ? "2" : eff === "MAR" ? "3" : eff === "APR" ? "4" : eff === "MAY" ? "5" : eff === "JUN" ? "6" : eff === "JUL" ? "7" : eff === "AUG" ? "8" : eff === "SEP" ? "9" : eff === "OCT" ? "10" : eff === "NOV" ? "11" : "12";
                    if (month >= 9) {
                        year = year + 1
                    }
                    this.setState({ selectedYear: year.toString() });
                }
                this.setState({ selectedEffective: eff });
            }
            const sta = params.get("sta");
            if (sta && sta.length > 0 && sta.toLowerCase() !== "null" && sta !== "-1") { this.setState({ selectedStatus: sta }); }
            //in case we use State, Quoter, or Sales Rep in parameters
            //const stt = params.get("stt");
            //const qtr = params.get("qtr");
            //const rep = params.get("rep");
            //if (stt && stt.length > 0 && stt.toLowerCase() !== "null" && stt !== "-1") { this.setState({ selectedState: stt }); }
            //if (qtr && qtr.length > 0 && qtr.toLowerCase() !== "null" && qtr !== "-1") { this.setState({ selectedQuoter: qtr }); }
            //if (rep && rep.length > 0 && rep.toLowerCase() !== "null" && rep !== "-1") { this.setState({ selectedRep: rep }); }
        }
        //filters...  states, quoters,reps
        Promise.all([dataApi.getAreas(), dataApi.getQuoters(), dataApi.getSalesReps()])
            .then(responses => {
                if (responses && responses[0] && responses[1] && responses[2] && this._mounted) {
                    this.setState({
                        states: responses[0],
                        quoters: responses[1],
                        reps: responses[2],
                    }, () => {
                        this._getQuotes();
                    });
                }
            });
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    _getQuotes = () => {
        const { selectedState, selectedEffective, selectedYear, selectedQuoter, selectedRep, selectedStatus } = this.state;
        this.setState({ loading: true });
        Promise.all([quotesApi.getPaged(1, null, null, true, false, selectedState, selectedEffective, selectedYear, selectedQuoter, selectedRep, selectedStatus), quotesApi.getPaged(1, null, null, false, false, selectedState, selectedEffective, selectedYear, selectedQuoter, selectedRep, selectedStatus), quotesApi.getPaged(1, null, null, true, true, selectedState, selectedEffective, selectedYear, selectedQuoter, selectedRep, selectedStatus)])
            .then(responses => {
                this.setState({ loading: false });
                if (responses && responses[0] && responses[1] && responses[2] && this._mounted) {
                    this.setState({
                        allQuotes: responses[0],
                        allQuotesCount: responses[0][0] ? responses[0][0].recordCount : 0,
                        allQuotesMore: responses[0][0] ? responses[0][0].recordCount > 100 : false,
                        myQuotes: responses[1],
                        myQuotesCount: responses[1][0] ? responses[1][0].recordCount : 0,
                        myQuotesMore: responses[1][0] ? responses[1][0].recordCount > 100 : false,
                        testQuotes: responses[2],
                        testQuotesCount: responses[2][0] ? responses[2][0].recordCount : 0,
                        testQuotesMore: responses[2][0] ? responses[2][0].recordCount > 100 : false,
                    });
                } else {
                    this.setState({
                        allQuotes: [],
                        allQuotesCount: 0,
                        allQuotesMore: false,
                        myQuotes: [],
                        myQuotesCount: 0,
                        myQuotesMore: false,
                        testQuotes: [],
                        testQuotesCount: 0,
                        testQuotesMore: false,
                    });
                }
            });
    };

    _handleTextChange = e => {
        const { name, value } = e.target;
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
            };
        });
    };

    _searchResultSelected = (item) => {
        if (item.type === "Quote") {
            this.props.history.push(routeHelpers.quoteDetail(item.value));
        }
    };

    _handleRowClick = id => {
        this.props.history.push(routeHelpers.quoteDetail(id));
    };

    _allQuotesScroll = (page, col, dir) => {
        const { selectedState, selectedEffective, selectedYear, selectedQuoter, selectedRep, selectedStatus } = this.state;
        if (((page - 1) * 100) < this.state.allQuotesCount) {
            quotesApi.getPaged(page, col, dir, true, false, selectedState, selectedEffective, selectedYear, selectedQuoter, selectedRep, selectedStatus).then(result => {
                let joined = {};
                if (page === 1) {
                    joined = result;
                } else {
                    joined = this.state.allQuotes.concat(result);
                }
                this.setState({
                    allQuotes: joined,
                });
            });
        }
        else {
            this.setState({
                allQuotesMore: false,
            });
        }
    };

    _myQuotesScroll = (page, col, dir) => {
        const { selectedState, selectedEffective, selectedYear, selectedQuoter, selectedRep, selectedStatus } = this.state;
        if (((page - 1) * 100) < this.state.myQuotesCount) {
            quotesApi.getPaged(page, col, dir, false, false, selectedState, selectedEffective, selectedYear, selectedQuoter, selectedRep, selectedStatus).then(result => {
                let joined = {};
                if (page === 1) {
                    joined = result;
                } else {
                    joined = this.state.myQuotes.concat(result);
                }
                this.setState({
                    myQuotes: joined,
                });
            });
        }
        else {
            this.setState({
                myQuotesMore: false,
            });
        }
    };

    _testQuotesScroll = (page, col, dir) => {
        const { selectedState, selectedEffective, selectedYear, selectedQuoter, selectedRep, selectedStatus } = this.state;
        if (((page - 1) * 100) < this.state.testQuotesCount) {
            quotesApi.getPaged(page, col, dir, true, true, selectedState, selectedEffective, selectedYear, selectedQuoter, selectedRep, selectedStatus).then(result => {
                let joined = {};
                if (page === 1) {
                    joined = result;
                } else {
                    joined = this.state.testQuotes.concat(result);
                }
                this.setState({
                    testQuotes: joined,
                });
            });
        }
        else {
            this.setState({
                testQuotesMore: false,
            });
        }
    };

    _changeState = (item) => {
        this.setState({
            selectedState: item,
        }, () => {
            this._getQuotes();
        });
    };

    _changeEffective = (item) => {
        this.setState({
            selectedEffective: item,
        }, () => {
            this._getQuotes();
        });
    };

    _changeYear = (item) => {
        this.setState({
            selectedYear: item,
        }, () => {
            this._getQuotes();
        });
    };

    _changeQuoter = (item) => {
        this.setState({
            selectedQuoter: item,
        }, () => {
            this._getQuotes();
        });
    };

    _changeRep = (item) => {
        this.setState({
            selectedRep: item,
        }, () => {
            this._getQuotes();
        });
    };

    _changeStatus = (item) => {
        this.setState({
            selectedStatus: item,
        }, () => {
            this._getQuotes();
        });
    };

    _clearFilters = () => {
        this.setState({
            selectedState: "-1",
            selectedEffective: "-1",
            selectedYear: "-1",
            selectedQuoter: "-1",
            selectedRep: "-1",
            selectedStatus: "-1",
        }, () => {
            this._getQuotes();
        });
    };

    renderTable = (what) => {
        const { states, effectives, years, quoters, reps, statuses, selectedState, selectedEffective, selectedYear, selectedQuoter, selectedRep, selectedStatus } = this.state;
        let quotes = what === 0 ? this.state.allQuotes : what === 1 ? this.state.myQuotes : this.state.testQuotes;
        let count = what === 0 ? this.state.allQuotesCount : what === 1 ? this.state.myQuotesCount : this.state.testQuotesCount;
        let more = what === 0 ? this.state.allQuotesMore : what === 1 ? this.state.myQuotesMore : this.state.testQuotesMore;
        let fnScroll = what === 0 ? this._allQuotesScroll : what === 1 ? this._myQuotesScroll : this._testQuotesScroll;
        return (
            <div>
                <div className={css(styles.columnFilterContainer)}>
                    <div className={css(styles.filterText)}>Filter By:</div>
                    <div className={css(styles.dropdownHolder)}>
                        <Dropdown
                            aStyles={styles.dropdown}
                            options={[{ label: "All states", value: "-1" }].concat(states.map(item => ({
                                label: item.name,
                                value: item.id.toString()
                            })))}
                            value={selectedState ? selectedState : ""}
                            placeholder={strings.statePlaceHolder}
                            onChange={this._changeState}
                            hideLabel={true}
                            size={inputSizes.small}
                        />
                    </div>
                    <div className={css(styles.dropdownHolder)}>
                        <Dropdown
                            aStyles={styles.dropdown}
                            options={[{ label: "All effectives", value: "-1" }].concat(effectives.map(item => ({
                                label: item.name,
                                value: item.id.toString()
                            })))}
                            value={selectedEffective ? selectedEffective : ""}
                            placeholder={strings.effectivePlaceHolder}
                            onChange={this._changeEffective}
                            hideLabel={true}
                            size={inputSizes.small}
                        />
                    </div>
                    <div className={css(styles.dropdownHolder)}>
                        <Dropdown
                            aStyles={styles.dropdown}
                            options={[{ label: "All years", value: "-1" }].concat(years.map(item => ({
                                label: item,
                                value: item
                            })))}
                            value={selectedYear ? selectedYear : ""}
                            placeholder={strings.yearPlaceHolder}
                            onChange={this._changeYear}
                            hideLabel={true}
                            size={inputSizes.small}
                        />
                    </div>
                    <div className={css(styles.dropdownHolder)}>
                        <Dropdown
                            aStyles={styles.dropdown}
                            options={[{ label: "All quoters", value: "-1" }].concat(quoters.map(item => ({
                                label: item.fullName,
                                value: item.id.toString()
                            })))}
                            value={selectedQuoter ? selectedQuoter : ""}
                            placeholder={strings.quoterPlaceHolder}
                            onChange={this._changeQuoter}
                            hideLabel={true}
                            size={inputSizes.small}
                        />
                    </div>
                    <div className={css(styles.dropdownHolder)}>
                        <Dropdown
                            aStyles={styles.dropdown}
                            options={[{ label: "All sales reps", value: "-1" }].concat(reps.map(item => ({
                                label: item.fullName,
                                value: item.id.toString()
                            })))}
                            value={selectedRep ? selectedRep : ""}
                            placeholder={strings.repPlaceHolder}
                            onChange={this._changeRep}
                            hideLabel={true}
                            size={inputSizes.small}
                        />
                    </div>
                    <div className={css(styles.dropdownHolder)}>
                        <Dropdown
                            aStyles={styles.dropdown}
                            options={[{ label: "All statuses", value: "-1" }].concat(statuses.map(item => ({
                                label: item.name,
                                value: item.name
                            })))}
                            value={selectedStatus ? selectedStatus : ""}
                            placeholder={strings.statusPlaceHolder}
                            onChange={this._changeStatus}
                            hideLabel={true}
                            size={inputSizes.small}
                        />
                    </div>
                    <div className={css(styles.resetButtonHolder)}>
                        <button type="button" className={css(styles.resetButton)} onClick={this._clearFilters}>Reset</button>
                    </div>
                </div>
                <div className={css(styles.right)}>{parseInt(count).toLocaleString()} quotes</div>
                {quotes.length > 0 ? (
                    <Infinite
                        results={quotes}
                        length={quotes.length}
                        columnLabels={this.state.columnLabels}
                        columnValues={this.state.columnValues}
                        showChevron={true}
                        showMore={more}
                        onClick={this._handleRowClick}
                        onScroll={fnScroll} />
                ) : (
                    <div className={css(styles.noResults)}>{this.state.loading ? "Loading " : "No Matching "} quotes</div>
                )}
            </div>
        );
    }

    render() {
        return (
            <div className={css(styles.content)}>
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>
                        <PageTitle>{strings.title}</PageTitle>
                    </div>
                    <div className={css(styles.quotes)}>
                        <div>
                            <SearchInput
                                aStyles={styles.searchBox}
                                search={quotesApi.searchQuotes}
                                onItemSelected={this._searchResultSelected}
                                placeholder={strings.searchPlaceholder}
                                hideLabel={true}
                            />
                        </div>
                        <Tabs>
                            <div label="All Quotes">
                                {this.renderTable(0)}
                            </div>
                            <div label="My Quotes">
                                {this.renderTable(1)}
                            </div>
                            <div label="Test Quotes">
                                {this.renderTable(2)}
                            </div>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}
