import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import sortBy from "lodash.sortby";
import Modal from "../Modal";
import TextInput from "../inputs/TextInput";
import Dropdown from "../inputs/Dropdown";
import NestedDropdown from "../inputs/NestedDropdown";
import Toggle from "../inputs/Toggle";
import backCircle from "../../images/SVGs/BackCircle.svg";
import ActionButton from "../ActionButton";
import coverageTypes from "../../constants/coverageTypes";
import * as texchangePropTypes from "../../texchangePropTypes";
import * as color from "../../constants/color";
import * as util from "../../lib/util";
import * as inputSizes from "../../constants/inputSizes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = StyleSheet.create({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    planAndCensusContainer: {
        color: color.darkestGray,
        width: "24%",
        flex: 1,
        padding: 8,
        paddingTop: 124,
        boxShadow: "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)",
        marginBottom: 400,
    },
    plansContainer: {
        display: "flex",
        flexShrink: 0,
        width: "76%",
        marginLeft: 0,
        padding: 4,
        paddingTop: 35,
        overflowX: "auto",
        overflowY: "hidden",
    },
    scrollButtons: {
        position: "absolute",
        top: 408,
        left: "calc(50% + 86px)",
        width: 100,
        height: 24,
        whiteSpace: "nowrap",
    },
    scrollButton: {
        position: "relative",
        cursor: "pointer",
        border: "none",
        backgroundColor: "transparent",
        display: "inline-block",
        margin: "0px 30px",
        height: 24,
    },
    rotate: {
        transform: "rotate(180deg)",
    },
    planColumn: {
        width: "30%",
        minWidth: 410,
        flex: 1,
        paddingLeft: 8,
        paddingRight: 8,
    },
    planColumnTitle: {
        fontSize: 18,
        color: color.mediumYellow1,
        padding: "4px 4px",
        textAlign: "center",
        height: 24,
    },
    planDropdown: {
        marginBottom: 0,
    },
    planDetails: {
        height: "calc(100% - 68px)",
    },
    emptyPlan: {
        color: color.mediumYellow1,
        textAlign: "center",
        minHeight: "400px",
    },
    emptyPlanPlus: {
        marginTop: 100,
        fontSize: 50,
        fontWeight: 100,
    },
    emptyPlanText: {
        fontSize: 22,
        marginTop: 12,
    },
    tableHolder: {
        borderTop: `1px solid ${color.darkGray2}`,
        borderLeft: `1px solid ${color.darkGray2}`,
        borderRight: `1px solid ${color.darkGray2}`,
    },
    hide: {
        display: "none",
    },
    planLink: {
        color: color.borderBackgroundGray,
        textDecoration: "underline",
    },
    buttonContainer: {
        height: 60,
        margin: "12px auto",
        textAlign: "center",
        verticalAlign: "top",
        width: "100%",
    },
    saveButton: {
        minWidth: 200,
        marginLeft: 20,
    },
    toggle: {
        display: "inline-flex",
        verticalAlign: "top",
        marginTop: 16,
        fontSize: 15,
    },
    noPlansContainer: {
        backgroundColor: color.lightGray,
        color: color.red,
        fontSize: 18,
        textAlign: "center",
        padding: "50px",
        lineHeight: "22px",
    },
    censusTable: {
        width: "100%",
        borderCollapse: "collapse",
    },
    censusTableHeader: {
        fontSize: 13,
        color: color.mediumYellow1,
        padding: "8px 8px",
        textAlign: "left",
        height: 40,
        fontWeight: 500,
        whiteSpace: "nowrap",
        borderBottom: `1px solid ${color.mediumGray}`,
    },
    censusTableCell: {
        fontSize: 14,
        color: color.tableText,
        padding: "8px 8px",
        textAlign: "left",
        height: 68,
        borderBottom: `1px solid ${color.tableBorder}`,
        lineHeight: "16px",
        ":nth-child(1)": {
            fontWeight: 500,
        },
        ":nth-child(3)": {
            fontWeight: 500,
        },
        ":nth-child(7)": {
            maxWidth: 350,
        },
    },
    censusTableCellGray: {
        fontSize: 13,
        color: color.mediumYellow1,
        backgroundColor: color.lightGray5,
    },
    censusTableCellRed: {
        backgroundColor: "#E0A298",
    },
    loadSavedSpreadsheetContainer: {
        position: "absolute",
        right: 0,
        display: "flex",
        marginTop: -68,
        marginRight: 24,
    },
    loadSpreadsheetDropdown: {
        marginRight: 20,
        minWidth: 200,
        top: -10,
    },
    saveSpreadsheetButton: {
        display: "inline-block",
        height: 40,
        fontSize: 14,
    },
    modalContent: {
        width: 386,
    },
    modalInstructions: {
        color: color.darkestGray,
        fontSize: 16,
        marginBottom: 8,
    },
    modalButtonContainer: {
        marginTop: 24,
        display: "flex",
        justifyContent: "space-between",
    },
    modalButton: {
        width: 150,
    }
});

const strings = {
    required: "Please fill out this field",
    carrierPlaceholder: "Select Carrier",
    carrierAbovePlaceholder: "Carrier must be selected",
    bulkPlanPlaceholder: "Select Bulk Mapping Plan",
    newPlanPlaceholder: "Select Mapped Plan",
    newPlanDoesNotQualify: "Plan not available for this member",
    spreadsheetNameInstructions: "Please name your spreadsheet:",
    spreadsheetNamePlaceholder: "Enter spreadsheet name. (max length 30)",
    spreadsheetsPlaceholder: "Load Spreadsheet",
    saveSpreadsheetButtonText: "Save Spreadsheet",
    saveButtonText: "Save",
    cancelButtonText: "Cancel",
    optionsRequiredMessage: "Please configure at least one Alternative Mapping",
};

const inputNames = {
    spreadsheetName: "spreadsheetName",
    rateType: "rateType",
};

export class StackedMultiOptionSpreadsheet extends Component {
    static propTypes = {
        quote: texchangePropTypes.quote.isRequired,
        selectedPlans: PropTypes.object.isRequired,
        spreadsheets: PropTypes.object.isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        setSubStep: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            options: Array.apply(null, Array(24)).map(() => {
                return {};
            }),
            scrollIndex: 1,
            filteredCarriers: [],
            filteredPlans: [],
            inputs: {
                [inputNames.spreadsheetName]: "",
                [inputNames.rateType]: "Age", //(props.quote && (props.quote.areaId === 7 || props.quote.areaId === 23 || props.quote.areaId === 32)) ? "Age" : "Composite",
            },
            errors: [],
            showSaveModal: false,
            currentPlans: this._getCurrentPlans(),
            selectedSpreadsheetName: null,
        };
    }

    componentDidUpdate(prevProps) {
        //if current plans change... reset the current plans
        if (this.props.quote.currentMedicalPlans.length !== prevProps.quote.currentMedicalPlans.length) {
            const currentPlans = this._getCurrentPlans();

            this.setState({
                currentPlans,
            });
        }
        //if selected plans change... clear any selections out...
        if (this.props.selectedPlans !== prevProps.selectedPlans) {
            this.setState({
                options: Array.apply(null, Array(24)).map(() => {
                    return {};
                }),
                scrollIndex: 1,
                filteredCarriers: [],
                filteredPlans: [],
                errors: [],
                showSaveModal: false,
                selectedSpreadsheetName: null,
            }, () => {
                this._checkScrollLeft();
            });
        }
    }

    _checkScrollLeft = () => {
        let timesRun = 0;

        const checkScroll = function () {
            const scroller = document.getElementById("scroller2");
            if (scroller && scroller.checkVisibility()) {
                if (scroller.offsetLeft > 0) {
                    scroller.scrollTo({ left: 0, behavior: "smooth" });
                    return;
                }
            } else {
                if (++timesRun < 60) {
                    setTimeout(checkScroll, 1500);
                }
            }
        }.bind(this);

        checkScroll();
    };

    _getPlansByCoverageType = () => {
        const { quote, selectedPlans } = this.props;

        const currentPlans = quote[`currentMedicalPlans`];
        const renewalPlans = quote[`renewalMedicalPlans`];
        let newPlans = [];
        if (selectedPlans.medical && selectedPlans.medical.length > 0) {
            const isAgeRated = this.state.inputs[inputNames.rateType] === "Age";

            if (isAgeRated) {
                newPlans = selectedPlans.medical.filter(p => p.ageMonthlyPremium !== "$0.00");
            } else {
                newPlans = selectedPlans.medical.filter(p => p.monthlyPremium !== "$0.00");
            }
        }

        return {
            currentPlans,
            renewalPlans,
            newPlans,
        };
    };

    _getPlanOptionItem = item => {
        return {
            value: {
                id: item.id,
                displayName: item.displayName,
                carrierId: item.carrierId,
                carrierAbbr: item.carrierAbbr,
                packageName: item.packageName,
                networkName: item.networkName
            },
            label: `${item.displayName} - ${item.individualDeductibleIn} ded`,
        };
    };

    _getCarrierOptionItem = item => {
        return {
            value: item.carrierId,
            label: item.carrierName,
        };
    };

    _getCarrierList = () => {
        const { filteredCarriers } = this.state;
        if (filteredCarriers.length > 0) {
            return filteredCarriers;
        }
        //continue
        const { selectedPlans } = this.props;

        let newPlans = [];
        let carriers = [];
        if (selectedPlans.medical && selectedPlans.medical.length > 0) {
            const isAgeRated = this.state.inputs[inputNames.rateType] === "Age";

            if (isAgeRated) {
                newPlans = selectedPlans.medical.filter(p => p.ageMonthlyPremium !== "$0.00");
            } else {
                newPlans = selectedPlans.medical.filter(p => p.monthlyPremium !== "$0.00");
            }
        }

        if (newPlans.length > 0) {
            newPlans = newPlans.map(this._getCarrierOptionItem).filter((value, index, self) => self.indexOf(value) === index);
            const flag = {};
            newPlans.forEach(item => {
                if (!flag[item.value]) {
                    flag[item.value] = true;
                    carriers.push(item);
                }
            });
            carriers = sortBy(carriers, p => p.label);
            //store the list
            this.setState({
                filteredCarriers: carriers,
            });
        }
        return carriers;
    };

    _selectedCarrierChanged = e => {
        const { quote } = this.props;
        const { options, filteredPlans, filteredCarriers } = this.state;
        const index = parseInt(e.name);
        const carrierId = parseInt(e.value);
        options[index].carrierId = carrierId;
        options[index].carrier = filteredCarriers.find(c => c.value === carrierId).label;

        //get the plans for this carrier from the filteredPlans list
        let availablePlans = filteredPlans.length > 0 ? filteredPlans : this._getPlanList();

        //first filter the list by selected carrier
        if (quote.areaId === 32 && carrierId !== 63 && carrierId !== 90 && carrierId !== 93) {
            //add Kaiser at the end
            const kaiser = availablePlans.filter(p => p.carrierId === 63);
            availablePlans = availablePlans.filter(p => p.carrierId === carrierId);
            if (availablePlans && availablePlans.length > 0 && kaiser && kaiser.length > 0) {
                //add the term "Kaiser" at the front of each Kaiser plan
                kaiser.forEach(pkg => {
                    if (pkg.options && pkg.options.length > 0) {
                        pkg.options.forEach(opt => {
                            if (!opt.label.startsWith("[Kaiser] ")) {
                                opt.label = "[Kaiser] " + opt.label;
                            }
                        });
                    }
                });
                availablePlans = availablePlans.concat(kaiser);
            }
        } else {
            availablePlans = availablePlans.filter(p => p.carrierId === carrierId);
        }

        //save this ALL list to the column
        options[index].availablePlans = availablePlans;

        //set available plans for each member in this column
        const enrolling = this.props.quote.census.filter(c => c.relationship === "EE" && c.medicalCoverageId < 5);
        if (enrolling.length > 0) {
            //check to make sure we have a member array setup
            if (!options[index].enrolling) {
                const memberArray = Array.apply(null, Array(enrolling.length)).map(() => {
                    return {};
                });
                options[index].enrolling = memberArray;
                options[index].usedCurrent = false;
                options[index].usedBulk = false;
            }
            //place values into collection
            for (let memberIndex = 0; memberIndex < enrolling.length; memberIndex++) {
                //clear out any previously saved plan values
                options[index].enrolling[memberIndex].censusId = null;
                options[index].enrolling[memberIndex].newMedicalPlanId = null;
                options[index].enrolling[memberIndex].newMedicalPlanName = "";
                options[index].enrolling[memberIndex].newPlanAvailable = "";
                //filter plans for this member
                const availablePlansForMember = availablePlans.map(pkg => ({
                    ...pkg,
                    options: pkg.options.filter(o => o.value && (!o.value.censusNotQualifying || o.value.censusNotQualifying.indexOf(enrolling[memberIndex].id.toString()) === -1)),
                }));
                //remove empty top-level options
                const reducedPlans = availablePlansForMember.filter(p => p.options.length > 0);
                options[index].enrolling[memberIndex].availablePlans = reducedPlans;
            }
        }
        this.setState({ options });
    };

    _getPlanList = () => {
        const plansByType = this._getPlansByCoverageType();
        const newPlans = plansByType.newPlans.map(this._getPlanOptionItem);
        //filter by carrier and network/package
        const planList = [];
        if (newPlans.length > 0) {
            const grouped = newPlans.reduce((rv, x) => {
                const network = x.value["carrierAbbr"] === "UHC" && x.value["packageName"] ? " (" + x.value["packageName"] + ")" : x.value["networkName"] ? " (" + x.value["networkName"] + ")" : " (No Network)";
                (rv[x.value["carrierAbbr"] + network] = rv[x.value["carrierAbbr"] + network] || []).push(x);
                return rv;
            }, {});

            Object.keys(grouped).forEach(key => {
                planList.push({
                    label: key,
                    carrierId: grouped[key][0].value.carrierId,
                    options: sortBy(grouped[key], p => p.label),
                });
            });
            //store the list
            this.setState({
                filteredPlans: planList,
            });
        }

        return sortBy(planList, p => p.label);
    };

    _selectedNewPlanChanged = e => {
        const split = e.name.split('_');
        const index = parseInt(split[0]);
        const idx = parseInt(split[1]);
        const { options } = this.state;
        //move new plan to the stored values
        e.value.value.type = e.value.type;
        const newPlan = e.value.value;
        //set census members that match
        const enrolling = this.props.quote.census.filter(c => c.relationship === "EE" && c.medicalCoverageId < 5);
        if (enrolling.length > 0) {
            //check to make sure we have an array setup
            if (!options[index].enrolling) {
                const memberArray = Array.apply(null, Array(enrolling.length)).map(() => {
                    return {};
                });
                options[index].enrolling = memberArray;
            }
            //check to see if bulk plans previously set
            if (options[index].usedBulk === "Y") {
                if (!window.confirm("You previously set some plans on this Alternative using Bulk mapping.  This action will overwrite previously set plans on certain members.")) {
                    return;
                }
            }
            //Set that current plan set was used
            options[index].usedCurrent = "Y";
            options[index].usedBulk = "";
            //place values into collection
            for (let memberIndex = 0; memberIndex < enrolling.length; memberIndex++) {
                if (enrolling[memberIndex].currentMedicalPlanId === this.state.currentPlans[idx].id) {
                    options[index].enrolling[memberIndex].censusId = enrolling[memberIndex].id;
                    if (!newPlan.censusNotQualifying || newPlan.censusNotQualifying.indexOf(enrolling[memberIndex].id) === -1) {
                        options[index].enrolling[memberIndex].newPlanAvailable = "Y";
                        options[index].enrolling[memberIndex].newMedicalPlanId = e.value.value.id;
                        options[index].enrolling[memberIndex].newMedicalPlanName = e.value.label;
                    } else if (options[index].enrolling[memberIndex].newMedicalPlanId === null) {
                        options[index].enrolling[memberIndex].newPlanAvailable = "N";
                        options[index].enrolling[memberIndex].newMedicalPlanName = "";
                    }
                }
            }
        }
        //save selected curreent plan mappings for this column
        if (!options[index].newPlans) {
            options[index].newPlans = [];
        }
        options[index].newPlans[idx] = newPlan;
        options[index].newPlans[idx].label = e.value.label;
        options[index].newPlans[idx].currentMedicalPlanId = this.state.currentPlans[idx].id;
        //clear bulk selection
        options[index].bulkPlan = null;
        options[index].bulkPlanId = null;
        options[index].bulkPlanName = null;
        //update state and then move to the next spot
        this.setState({ options });
    };

    _selectedBulkPlanChanged = e => {
        const index = parseInt(e.name);
        const { options } = this.state;
        //move bulk plan to the stored values
        e.value.value.type = e.value.type;
        const newPlan = e.value.value;
        //set census members that match
        const enrolling = this.props.quote.census.filter(c => c.relationship === "EE" && c.medicalCoverageId < 5);
        if (enrolling.length > 0) {
            //check to make sure we have an array setup
            if (!options[index].enrolling) {
                const memberArray = Array.apply(null, Array(enrolling.length)).map(() => {
                    return {};
                });
                options[index].enrolling = memberArray;
            }
            //check to see if bulk plans previously set
            if (options[index].usedCurrent === "Y") {
                if (!window.confirm("You previously set some plans on this Alternative using Current mapping.  This action will overwrite previously set plans on all members.")) {
                    return;
                }
            }
            //Set that bulk plan set was used
            options[index].usedBulk = "Y";
            options[index].usedCurrent = "";
            //place values into collection
            for (let memberIndex = 0; memberIndex < enrolling.length; memberIndex++) {
                options[index].enrolling[memberIndex].censusId = enrolling[memberIndex].id;
                if (!newPlan.censusNotQualifying || newPlan.censusNotQualifying.indexOf(enrolling[memberIndex].id) === -1) {
                    options[index].enrolling[memberIndex].newPlanAvailable = "Y";
                    options[index].enrolling[memberIndex].newMedicalPlanId = e.value.value.id;
                    options[index].enrolling[memberIndex].newMedicalPlanName = e.value.label;
                } else if (options[index].enrolling[memberIndex].newMedicalPlanId === null) {
                    options[index].enrolling[memberIndex].newPlanAvailable = "N";
                    options[index].enrolling[memberIndex].newMedicalPlanName = "";
                }
            }
        }
        //save selected bulk plan for this column
        options[index].bulkPlan = e.value.value;
        options[index].bulkPlanId = e.value.value.id;
        options[index].bulkPlanName = e.value.label;
        //clear any new plan selections
        options[index].newPlans = null;
        //update state and then move to the next spot
        this.setState({ options });
    };

    _selectedNewMappedPlanChanged = (e, columnIndex, memberIndex) => {
        const { options } = this.state;
        //move bulk plan to the stored values
        e.value.value.type = e.value.type;
        const newPlan = e.value.value;
        //set this census member
        const enrolling = this.props.quote.census.filter(c => c.relationship === "EE" && c.medicalCoverageId < 5);
        //check to make sure we have an array setup
        if (!options[columnIndex].enrolling) {
            const memberArray = Array.apply(null, Array(enrolling.length)).map(() => {
                return {};
            });
            options[columnIndex].enrolling = memberArray;
        }
        //set values for this member
        options[columnIndex].enrolling[memberIndex].censusId = enrolling[memberIndex].id;
        if (!newPlan.censusNotQualifying || newPlan.censusNotQualifying.indexOf(enrolling[memberIndex].id) === -1) {
            options[columnIndex].enrolling[memberIndex].newPlanAvailable = "Y";
            options[columnIndex].enrolling[memberIndex].newMedicalPlanId = newPlan.id;
            options[columnIndex].enrolling[memberIndex].newMedicalPlanName = e.value.label;
        }
        //update state
        this.setState({ options });
    };

    _rateTypeChanged = value => {
        let rateType;
        if (value) {
            rateType = "Age";
        } else {
            rateType = "Composite";
        }

        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.rateType]: rateType,
                },
            };
        });
    };

    _getCurrentPlans = () => {
        return this.props.quote[`current${util.capitalize(coverageTypes.medical)}Plans`];
    }

    _handleTextChange = e => {
        const { name } = e.target;
        const { value } = e.target;

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
                errors,
            };
        });
    };

    _shiftRight = index => {
        if (index > 22) {
            return;
        }
        //else
        if (index > 1) {
            document.getElementById("scroller2").scrollTo({ left: 410 * (index - 1), behavior: "smooth" });
        } else {
            document.getElementById("scroller2").scrollTo({ left: 0, behavior: "smooth" });
            index = 1;
        }
        this.setState({ scrollIndex: index });
    };

    _saveClicked = () => {
        const { options } = this.state;
        if (this.state.showSaveModal) {
            const spreadsheetName = this.state.inputs[inputNames.spreadsheetName];
            if (!spreadsheetName) {
                const errors = {};
                errors[inputNames.spreadsheetName] = strings.required;
                this.setState({ errors });
            } else {
                //loop
                let alternativeIndex = 1;
                let alternatives = [];
                let warnings = "";
                options.forEach((option, index) => {
                    if (option.enrolling) {
                        let planMapping = [];
                        let membersPassed = true;
                        option.enrolling.forEach(member => {
                            if (member.newMedicalPlanId) {
                                planMapping.push({
                                    censusId: member.censusId,
                                    newPlanId: member.newMedicalPlanId,
                                    newPlanName: member.newMedicalPlanName,
                                });
                            } else {
                                membersPassed = false;
                                //push an empty record
                                planMapping.push({
                                    censusId: member.censusId,
                                    newPlanId: 0,
                                    newPlanName: "",
                                });
                            }
                        });
                        //did all members have a mapped plan?
                        if (!membersPassed) {
                            warnings += "Alternative " + (index + 1) + " - not all members are assigned a plan option.  ";
                        }
                        //continue
                        alternatives.push({
                            alternative: alternativeIndex,
                            carrier: option.carrier,
                            planMapping: planMapping,
                        });
                        alternativeIndex++;
                    } else if (option.availablePlans) {
                        warnings += "Alternative " + (index + 1) + " was skipped because no plans were chosen.  ";
                    }
                });

                //validation errors?
                if (alternatives.length === 0) {
                    toast.error("Unable to map any alternatives.  Please complete at least one alternative.", { toastId: 1 });
                    this.setState({ showSaveModal: false });
                    return;
                } else if (warnings) {
                    toast.warning("Spreadsheet was saved, but we encountered some issues.  " + warnings, { toastId: 1 });
                    this.setState({ showSaveModal: false });
                }

                //send to onSave
                const spreadsheet = {
                    name: spreadsheetName,
                    date: new Date(),
                    rateType: this.state.inputs[inputNames.rateType],
                    alternatives: alternatives,
                };

                this.props.onSave(spreadsheet);

                this.setState({
                    showSaveModal: false,
                    selectedSpreadsheetName: null,
                    options: Array.apply(null, Array(24)).map(() => {
                        return {};
                    }),
                }, () => {
                    toast.success("Save Successful", { toastId: 1 });
                    document.getElementById("scroller2").scrollTo({ left: 0, behavior: "smooth" });
                });
            }
        } else {
            if (options.filter(p => (p.bulkPlan || p.newPlans)).length === 0) {
                //loaded...  already set plans up on enrolling
                let loaded = false;
                options.forEach(option => {
                    if (option.enrolling && !loaded) {
                        if (option.enrolling.filter(p => p.newMedicalPlanId).length > 0) {
                            loaded = true;
                        }
                    }
                });
                if (loaded) {
                    this.setState({ showSaveModal: true });
                } else {
                    toast.warn(strings.optionsRequiredMessage, { toastId: 1 });
                }
            } else {
                this.setState({ showSaveModal: true });
            }
        }
    };

    _loadSpreadsheetChanged = value => {
        const { quote, spreadsheets } = this.props;
        const { filteredPlans, filteredCarriers } = this.state;

        const spreadsheet = spreadsheets.stackedMultiPlan.find(s => s.name === value);
        //const missingPlan = { "id": "-1", "displayName": "#MISSING REFERENCE#", "carrierName": "#PLEASE RE-SELECT#" };

        if (spreadsheet) {
            let error = false;

            const options = Array.apply(null, Array(24)).map(() => {
                return {};
            });

            //set available plans for each member in this column
            const enrolling = this.props.quote.census.filter(c => c.relationship === "EE" && c.medicalCoverageId < 5);

            //loop through alternatives
            spreadsheet.alternatives.forEach((alternative, index) => {
                const carrierMatch = filteredCarriers.find(c => c.label === alternative.carrier);
                if (!carrierMatch) {
                    //throw error about missing carrier
                    toast.error("Carrier " + alternative.carrier + " not found.  Did you change your selected plans?  Unable to load this spreadsheet.", { toastId: 1 });
                    return;
                }

                const carrierId = carrierMatch.value;
                //set carrier
                options[index].carrierId = carrierId;
                options[index].carrier = carrierMatch.label;

                //get the plans for this carrier from the filteredPlans list
                let availablePlans = filteredPlans.length > 0 ? filteredPlans : this._getPlanList();

                //first filter the list by selected carrier
                if (quote.areaId === 32 && carrierId !== 63 && carrierId !== 90 && carrierId !== 93) {
                    //add Kaiser at the end
                    const kaiser = availablePlans.filter(p => p.carrierId === 63);
                    availablePlans = availablePlans.filter(p => p.carrierId === carrierId);
                    if (availablePlans && availablePlans.length > 0 && kaiser && kaiser.length > 0) {
                        //add the term "Kaiser" at the front of each Kaiser plan
                        kaiser.forEach(pkg => {
                            if (pkg.options && pkg.options.length > 0) {
                                pkg.options.forEach(opt => {
                                    if (!opt.label.startsWith("[Kaiser] ")) {
                                        opt.label = "[Kaiser] " + opt.label;
                                    }
                                });
                            }
                        });
                        availablePlans = availablePlans.concat(kaiser);
                    }
                } else {
                    availablePlans = availablePlans.filter(p => p.carrierId === carrierId);
                }

                //save this ALL list to the column
                options[index].availablePlans = availablePlans;

                //Does the census and plan mapping from the stored spreadsheet match?
                if (enrolling.length > 0 && alternative.planMapping && enrolling.length === alternative.planMapping.length) {
                    //load the plan mapping... assuming the same census
                    const memberArray = Array.apply(null, Array(enrolling.length)).map(() => {
                        return {};
                    });
                    options[index].enrolling = memberArray;
                    //load the saved values
                    for (let memberIndex = 0; memberIndex < enrolling.length; memberIndex++) {
                        options[index].enrolling[memberIndex].censusId = enrolling[memberIndex].id;
                        options[index].enrolling[memberIndex].newMedicalPlanId = alternative.planMapping[memberIndex].newPlanId;
                        options[index].enrolling[memberIndex].newMedicalPlanName = alternative.planMapping[memberIndex].newPlanName;
                        options[index].enrolling[memberIndex].newPlanAvailable = "";
                        //filter plans for this member
                        const availablePlansForMember = availablePlans.map(pkg => ({
                            ...pkg,
                            options: pkg.options.filter(o => o.value && (!o.value.censusNotQualifying || o.value.censusNotQualifying.indexOf(enrolling[memberIndex].id.toString()) === -1)),
                        }));
                        //remove empty top-level options
                        const reducedPlans = availablePlansForMember.filter(p => p.options.length > 0);
                        options[index].enrolling[memberIndex].availablePlans = reducedPlans;
                    }
                }
            });

            //update page
            if (!error) {
                this.setState(prevState => {
                    return {
                        inputs: {
                            ...prevState.inputs,
                            [inputNames.spreadsheetName]: spreadsheet.name,
                            [inputNames.rateType]: spreadsheet.rateType,
                        },
                        options,
                        errors: [],
                        selectedSpreadsheetName: value,
                    };
                }, () => {
                    toast.success("All census members' plan selections have been loaded. See below.", { toastId: 1 });
                });
            } else {
                toast.error("Census members or plans from the saved spreadsheet do not match.  Unable to load members' plan selections for this spreadsheet.", { toastId: 1 });
            }
        }
    };

    _renderSpreadsheetsDropdown = () => {
        const { stackedMultiPlan } = this.props.spreadsheets;
        return (
            <div className={css(styles.loadSavedSpreadsheetContainer)}>
                {stackedMultiPlan && stackedMultiPlan.length > 0 &&
                    <Dropdown
                        aStyles={styles.loadSpreadsheetDropdown}
                        options={stackedMultiPlan.map(item => ({
                            label: item.name,
                            value: item.name,
                        }))}
                        value={this.state.selectedSpreadsheetName}
                        onChange={this._loadSpreadsheetChanged}
                        placeholder={strings.spreadsheetsPlaceholder}
                        hideLabel={true}
                        size="small"
                    />
                }
                <ActionButton
                    aStyles={styles.saveSpreadsheetButton}
                    label={strings.saveSpreadsheetButtonText}
                    type="button"
                    onClick={this._saveClicked}
                />
            </div>
        );
    };

    render() {
        const { selectedPlans } = this.props;
        const { showSaveModal, options, scrollIndex, currentPlans } = this.state;
        const hasPlans = selectedPlans.medical && selectedPlans.medical.length > 0;
        let hasCurrentMapped = false;
        const enrolling = this.props.quote.census.filter(c => c.relationship === "EE" && c.medicalCoverageId < 5);
        if (enrolling.length > 0 && currentPlans.length > 0) {
            for (let index = 0; index < enrolling.length; index++) {
                const match = currentPlans.find(p => p.id === enrolling[index].currentMedicalPlanId);
                if (match) {
                    hasCurrentMapped = true;
                    enrolling[index]["currentMedicalPlan"] = match.displayName + " - " + match.individualDeductibleIn + " ded";
                } else if (enrolling[index].currentMedicalPlanId) {
                    enrolling[index]["currentMedicalPlan"] = "Missing Reference!";
                } else {
                    enrolling[index]["currentMedicalPlan"] = "Unassigned";
                }
            }
        }

        /*
        const isAgeRated = this.state.inputs[inputNames.rateType] === "Age";
        const toggleAgeLabel = selectedPlans && selectedPlans.medical ? "Age (" + selectedPlans.medical.filter(p => p.ageMonthlyPremium !== "$0.00").length + ")" : "Age (0)";
        const toggleCompLabel = selectedPlans && selectedPlans.medical ? "Comp (" + selectedPlans.medical.filter(p => p.monthlyPremium !== "$0.00").length + ")" : "Comp (0)";
        */

        return (
            <React.Fragment>
                <ToastContainer position="top-center" autoClose={3500} />
                <Modal
                    show={showSaveModal}
                    header="Save Spreadsheet"
                >
                    <div className={css(styles.modalContent)}>
                        <div className={css(styles.modalInstructions)}>
                            {strings.spreadsheetNameInstructions}
                        </div>
                        <TextInput
                            name={inputNames.spreadsheetName}
                            onChange={this._handleTextChange}
                            placeholder={strings.spreadsheetNamePlaceholder}
                            required={true}
                            validationMessage={this.state.errors[inputNames.spreadsheetName]}
                            value={this.state.inputs[inputNames.spreadsheetName]}
                            size={inputSizes.small}
                            hideLabel={true}
                            maxLength={30}
                        />
                    </div>
                    <div className={css(styles.modalButtonContainer)}>
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.cancelButtonText}
                            invertColor={true}
                            type="button"
                            onClick={() => this.setState({ showSaveModal: false })}
                        />
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.saveButtonText}
                            onClick={this._saveClicked}
                            type="button"
                        />
                    </div>
                </Modal>
                {this._renderSpreadsheetsDropdown()}
                <div className={css(styles.container)}>
                    <div className={css(styles.noPlansContainer, hasPlans ? styles.hide : "")}>
                        (Medical) You must have New Plans selected in order to use this page.
                    </div>
                    <div className={css(styles.planAndCensusContainer, hasPlans ? "" : styles.hide)}>
                        <div className={css(styles.tableHolder)}>
                            {hasCurrentMapped &&
                                <table className={css(styles.censusTable)}>
                                    <thead>
                                        <tr>
                                            <th className={css(styles.censusTableHeader)}>Current Plan Mapping</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPlans.map((item, index) => (
                                            <tr key={index}>
                                                <td className={css(styles.censusTableCell)}>{item.displayName} - {item.individualDeductibleIn} ded</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            }
                            <table className={css(styles.censusTable)}>
                                <tbody>
                                    <tr>
                                        <td className={css(styles.censusTableCell, styles.censusTableCellGray)}>Bulk Mapping{" "}<span>&#8594;</span></td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={css(styles.censusTable)}>
                                <thead>
                                    <tr>
                                        <th className={css(styles.censusTableHeader)} colSpan={4}>Age Rate Mapping</th>
                                    </tr>
                                    <tr>
                                        <th className={css(styles.censusTableHeader)} style={{ width: "5%" }}>No.</th>
                                        <th className={css(styles.censusTableHeader)} style={{ width: "30%" }}>Name</th>
                                        <th className={css(styles.censusTableHeader)} style={{ width: "5%" }}>Elect</th>
                                        <th className={css(styles.censusTableHeader)} style={{ width: "60%" }}>Current Plan</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {enrolling.map((item, index) => (
                                        <tr key={index}>
                                            <td className={css(styles.censusTableCell)}>{item.sequenceNumber}</td>
                                            <td className={css(styles.censusTableCell)}>{item.fullName}</td>
                                            <td className={css(styles.censusTableCell)}>{item.medicalCoverage}</td>
                                            <td className={css(styles.censusTableCell)}>{item.currentMedicalPlan}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={css(styles.plansContainer, hasPlans ? "" : styles.hide)} id="scroller2">
                        <div className={css(styles.scrollButtons)}>
                            <img src={backCircle} className={css(styles.scrollButton)} alt="left" onClick={() => this._shiftRight(scrollIndex - 1)} />
                            <img src={backCircle} className={css(styles.scrollButton, styles.rotate)} alt="right" onClick={() => this._shiftRight(scrollIndex + 1)} />
                        </div>
                        {options.map((option, index) => (
                            <div className={css(styles.planColumn)} key={index}>
                                <div className={css(styles.planDetails)}>
                                    <div className={css(styles.planColumnTitle)}>
                                        Alternative {index + 1}
                                    </div>
                                    <Dropdown
                                        aStyles={styles.planDropdown}
                                        options={this._getCarrierList()}
                                        value={option.carrierId ? option.carrierId : null}
                                        onChange={this._selectedCarrierChanged}
                                        name={index.toString()}
                                        placeholder={strings.carrierPlaceholder}
                                        hideLabel={true}
                                        size="small"
                                    />
                                    <div className={css(styles.tableHolder)}>
                                        {hasCurrentMapped &&
                                            <table className={css(styles.censusTable)}>
                                                <thead>
                                                    <tr>
                                                        <th className={css(styles.censusTableHeader)}>Current Plan Mapping</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentPlans.map((item, idx) => (
                                                        <tr key={idx}>
                                                            <td className={css(styles.censusTableCell)}>
                                                                <NestedDropdown
                                                                    aStyles={styles.planDropdown}
                                                                    options={option.availablePlans ? option.availablePlans : []}
                                                                    value={option.newPlans && option.newPlans[idx] && option.newPlans[idx].id ? option.newPlans[idx].id.toString() : ""}
                                                                    onSelect={this._selectedNewPlanChanged}
                                                                    name={index.toString() + "_" + idx.toString()}
                                                                    placeholder={!option.availablePlans ? strings.carrierAbovePlaceholder : option.newPlans && option.newPlans[idx] && option.newPlans[idx].label ? option.newPlans[idx].label.substring(0, 46) : strings.newPlanPlaceholder}
                                                                    openLeft={index > 1 && index !== (scrollIndex - 1)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        }
                                        <table className={css(styles.censusTable)}>
                                            <tbody>
                                                <tr>
                                                    <td className={css(styles.censusTableCell, styles.censusTableCellGray)}>
                                                        <NestedDropdown
                                                            aStyles={styles.planDropdown}
                                                            options={option.availablePlans ? option.availablePlans : []}
                                                            value={option.bulkPlanId ? option.bulkPlanId.toString() : ""}
                                                            onSelect={this._selectedBulkPlanChanged}
                                                            name={index.toString()}
                                                            placeholder={!option.availablePlans ? strings.carrierAbovePlaceholder : option.bulkPlanName ? option.bulkPlanName.substring(0, 46) : strings.bulkPlanPlaceholder}
                                                            openLeft={index > 1 && index !== (scrollIndex - 1)}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {option.enrolling ? (
                                        <div className={css(styles.tableHolder)}>
                                            <table className={css(styles.censusTable)}>
                                                <thead>
                                                    <tr>
                                                        <th className={css(styles.censusTableHeader)}>Alternative {index + 1}</th>
                                                    </tr>
                                                    <tr>
                                                        <th className={css(styles.censusTableHeader)}>Mapped Plans</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {enrolling.map((member, index2) => (
                                                        <tr key={index + "-" + index2}>
                                                            <td className={css(styles.censusTableCell, (option.enrolling[index2].censusId && option.enrolling[index2].newPlanAvailable === "N") ? styles.censusTableCellRed : "")}>
                                                                <NestedDropdown
                                                                    aStyles={styles.planDropdown}
                                                                    options={option.enrolling[index2].availablePlans ? option.enrolling[index2].availablePlans : []}
                                                                    value={option.enrolling[index2].newMedicalPlanId ? option.enrolling[index2].newMedicalPlanId.toString() : ""}
                                                                    onSelect={e => this._selectedNewMappedPlanChanged(e, index, index2)}
                                                                    name={index.toString() + "_" + member.id.toString()}
                                                                    placeholder={option.enrolling[index2].newMedicalPlanName ? option.enrolling[index2].newMedicalPlanName.substring(0, 46) : (option.enrolling[index2].censusId && option.enrolling[index2].newPlanAvailable === "N") ? strings.newPlanDoesNotQualify : strings.newPlanPlaceholder}
                                                                    openLeft={index > 1 && index !== (scrollIndex - 1)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div className={css(styles.emptyPlan)} onClick={() => this._shiftRight(index)}>
                                            <div className={css(styles.emptyPlanPlus)}>+</div>
                                            <div className={css(styles.emptyPlanText)}>
                                                Alternative {index + 1} Mapping
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = state => ({
    quote: state.proposal.quote,
    selectedPlans: state.proposal.selectedPlans,
    spreadsheets: state.proposal.spreadsheets,
});

export default connect(mapDispatchToProps)(StackedMultiOptionSpreadsheet);
