import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container, Row, Column } from "../grid";
import { StyleSheet, css } from "aphrodite";
import { ReactComponent as SpreadsheetIcon } from "../../images/spreadsheet-icon.svg";
import { ReactComponent as ProposalDocIcon } from "../../images/proposal-doc-icon.svg";
import { ReactComponent as PlansIcon } from "../../images/plans-icon.svg";
//import { ReactComponent as LoadDocIcon } from "../../images/SVGs/load-doc.svg";
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as quoteApi from "../../lib/api/quotes";
import * as proposalActions from "../../actions/proposalActions";
import * as texchangePropTypes from "../../texchangePropTypes";
import * as color from "../../constants/color";
import * as quoteProposalApi from "../../lib/api/quoteProposal";
import * as dataApi from "../../lib/api/data";
import * as favoriteApi from "../../lib/api/favorite";
import CurrentPlans from "./CurrentPlans";
import RenewalPlans from "./RenewalPlans";
import NewPlans from "./NewPlans";
import Spreadsheets from "./Spreadsheets";
import Output from "./Output";
import Spinner from "../Spinner";

const styles = StyleSheet.create({
    proposalBuilderContainer: {
        width: "100%",
        marginBottom: 40,
    },
    blueBar: {
        backgroundColor: color.darkGray7,
        height: 52,
    },
    blueBarNavItem: {
        color: color.blueGray,
        float: "left",
        width: "16.6666667%",
        textAlign: "center",
        fontSize: 16,
        fontWeight: 500,
        cursor: "pointer",
        verticalAlign: "middle",
        lineHeight: "52px",
        height: 52,
    },
    blueBarTextActive: {
        color: color.white,
        borderBottom: `4px solid ${color.lightOrange}`,
    },
    blueBarTextRequired: {
        height: 52,
        paddingTop: 14,
        lineHeight: "normal",
    },
    blueBarSubText: {
        fontSize: 10,
        textAlign: "center",
        marginTop: -2,
        lineHeight: "normal",
    },
    inThisSection: {
        backgroundColor: color.white,
        padding: 20,
        paddingBottom: 60,
        fontSize: 20,
    },
    inThisSectionTitle: {
        fontSize: 30,
        color: color.darkRed,
        marginTop: 40,
    },
    right: {
        float: "right",
        position: "relative",
        top: -38,
        right: 20,
        fontSize: 14,
        color: color.tableText,
    },
    blueBarText: {
        display: "inline-block",
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: 22,
        paddingRight: 22,
        height: 52,
    },
    proposalContainer: {
        marginLeft: 24,
    },
    rightSideContainer: {
        borderRadius: 4,
        border: `1px solid ${color.darkGray2}`,
        padding: 16,
        paddingTop: 16,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16,
        marginTop: 20,
    },
    rightSideHeaderText: {
        fontSize: 18,
        fontWeight: 500,
        paddingBottom: 12,
        marginBottom: 6,
        borderBottom: `1px solid ${color.mediumRed}`,
    },
    rightSideItem: {
        display: "flex",
        fontSize: 15,
        paddingTop: 8,
        paddingBottom: 8,
    },
    rightSideItemCarrier: {
        color: color.darkGray,
        flexBasis: "50%",
    },
    rightSideItemDate: {
        color: color.darkGray,
        flexBasis: "20%",
    },
    rightSideItemName: {
        color: color.mediumYellow1,
        flexBasis: "70%",
        marginLeft: 12,
        marginRight: 20,
    },
    rightSideItemIcon: {
        flexBasis: "10%",
        textAlign: "right",
    },
    rightSideItemText: {
        flexBasis: "80%",
        marginLeft: 12,
        marginRight: 20,
        fontSize: 14,
    },
    rightSideItemSuccess: {
        color: color.mediumGreen,
    },
    rightSideItemErr: {
        color: color.darkRed,
    },
    rightSideItemInfo: {
        color: color.mediumYellow1,
    },
    sectionContainer: {
        display: "flex",
        paddingTop: 16,
        paddingBottom: 16,
    },
    sectionIcon: {
        flexBasis: "25%",
        textAlign: "left",
    },
    sectionText: {
        flexBasis: "75%",
        textAlign: "left",
    },
    sectionItemTitle: {
        fontSize: 22,
        fontWeight: 500,
    },
    sectionItemBullet: {
        fontSize: 16,
        color: color.darkGray,
        paddingLeft: 20,
    },
    proposalBuilderIcon: {
        marginLeft: 20,
        marginRight: 20,
        verticalAlign: "middle",
        cursor: "pointer",
        width: 70,
        height: 70,
    },
    hide: {
        display: "none",
    },
    instructions: {
        color: color.darkestGray,
        paddingTop: 4,
    },
    badge: {
        marginLeft: 4,
        padding: "0px 3px",
        fontSize: 14,
        color: color.darkRed,
        backgroundColor: color.white,
        borderRadius: 12,
        border: `1px solid ${color.darkGray2}`,
    },
    badgeRed: {
        marginLeft: 4,
        padding: "0px 3px",
        fontSize: 14,
        color: color.white,
        backgroundColor: color.red,
        borderRadius: 12,
        border: `1px solid ${color.darkGray2}`,
    },
});

const strings = {
    sectionHeaderText: "In this section:",
    required: "required",
    proposalHome: "Proposal Home",
    currentPlans: "Current Plans",
    renewalPlans: "Renewal Plans",
    newPlans: "New Plans",
};

export class Landing extends Component {
    static propTypes = {
        quote: texchangePropTypes.quote.isRequired,
        selectedPlans: PropTypes.object.isRequired,
        spreadsheets: PropTypes.object.isRequired,
        setSelectedPlans: PropTypes.func.isRequired,
        setSpreadsheets: PropTypes.func.isRequired,
        setCarriers: PropTypes.func.isRequired,
        setPlanTypes: PropTypes.func.isRequired,
        setNetworks: PropTypes.func.isRequired,
        setMinMaxFilters: PropTypes.func.isRequired,
        setFavoriteLists: PropTypes.func.isRequired,
        setPlanTypesD: PropTypes.func.isRequired,
        setCoinsurancesD: PropTypes.func.isRequired,
        setDeductiblesD: PropTypes.func.isRequired,
        setPerioEndosD: PropTypes.func.isRequired,
        setAnnualMaximumsD: PropTypes.func.isRequired,
        setOrthoMaximumsD: PropTypes.func.isRequired,
        setReimbursementsD: PropTypes.func.isRequired,
        setExamCopaysV: PropTypes.func.isRequired,
        setFundingTypesV: PropTypes.func.isRequired,
        setMaterialCopaysV: PropTypes.func.isRequired,
        setFrameAllowancesV: PropTypes.func.isRequired,
        setContactLensAllowancesV: PropTypes.func.isRequired,
        setFrameFrequenciesV: PropTypes.func.isRequired,
        subStepChanged: PropTypes.func.isRequired,
        subStep: PropTypes.number.isRequired,
    };

    state = {
        messages: [],
        processes: [],
    };

    componentDidMount() {
        quoteProposalApi.getSpreadsheetByQuoteId(this.props.quote.id)
            .then(result => {
                if (result) {
                    this.props.setSpreadsheets(JSON.parse(result.spreadsheets));
                }
            });

        this._loadQuoteFilterData();
        //add click event
        document.body.addEventListener("click", this._handleBodyClicked);
    }

    componentWillUnmount() {
        const { quote, selectedPlans } = this.props;

        const newMedCount = selectedPlans.medical && selectedPlans.medical.length > 0 ? selectedPlans.medical.length : 0;
        const newDenCount = selectedPlans.dental && selectedPlans.dental.length > 0 ? selectedPlans.dental.length : 0;
        const newVisCount = selectedPlans.vision && selectedPlans.vision.length > 0 ? selectedPlans.vision.length : 0;
        const newLifCount = selectedPlans.life && selectedPlans.life.length > 0 ? selectedPlans.life.length : 0;
        //total counts
        const newCount = newMedCount + newDenCount + newVisCount + newLifCount;
        //unsaved plan selections
        if (quote.proposalCount === 0 && newCount > 0) {
            quoteApi.saveSelectedNewPlans(quote.id, selectedPlans.medical, selectedPlans.dental, selectedPlans.vision, selectedPlans.life);
        }
        //remove click event
        document.body.removeEventListener("click", this._handleBodyClicked);
    }

    _handleBodyClicked = e => {
        if (e.target && e.target.className && typeof e.target.className.includes === "function" && e.target.className.includes("modalBackground")) {
            //this._closeModal();
        }
    };

    _loadQuoteFilterData = () => {
        let timesRun = 0;
        let noMoreLoading = false;
        let waitingForCarrier = false;
        let currentCountWaitingForCarrier = 0;
        let newCountWaitingForCarrier = 0;
        let loopTime = 4000; //4 seconds
        const quoteId = this.props.quote.id;

        const checkOnQuote = function () {
            quoteApi.isQuoteReady(quoteId)
                .then(result => {
                    //max of 45 cycles...
                    if (!noMoreLoading && result && result.length && result.length > 0 && timesRun < 44) {
                        //update the carrier list
                        this.setState({
                            processes: result,
                        });

                        //any still loading?
                        const loading = result.find(p => p.status === "Loading");
                        noMoreLoading = !loading;
                        //complete?
                        if (noMoreLoading && !waitingForCarrier) {
                            //load everything available
                            const quote = this.props.quote;
                            const quoteCreateDate = new Date(quote.createDateString);
                            const todayDate = new Date();
                            //If Today AND if quote is a re-quote AND there are no selected plans
                            //AND this quote has no proposals, check/load selected plans from parent by re-loading the quote
                            if (quoteCreateDate.setHours(0, 0, 0, 0) === todayDate.setHours(0, 0, 0, 0) && quote.parentQuoteId && quote.proposalCount === 0 && quote.newMedicalPlans.length === 0 && quote.newDentalPlans.length === 0 && quote.newVisionPlans.length === 0 && quote.newLifePlans.length === 0) {
                                quoteApi.getById(quote.id)
                                    .then(response => {
                                        this.props.setSelectedPlans(this.props.selectedPlans, response.newMedicalPlans, "medical");
                                        this.props.setSelectedPlans(this.props.selectedPlans, response.newDentalPlans, "dental");
                                        this.props.setSelectedPlans(this.props.selectedPlans, response.newVisionPlans, "vision");
                                        this.props.setSelectedPlans(this.props.selectedPlans, response.newLifePlans, "life");
                                    });
                            }
                            //continue...
                            dataApi.getCarriersFilter(quoteId)
                                .then(response => {
                                    this.props.setCarriers(response);
                                });

                            dataApi.getMedicalPlanTypesFilter(quoteId)
                                .then(response => {
                                    this.props.setPlanTypes(response);
                                });

                            dataApi.getNetworksFilter(quoteId)
                                .then(response => {
                                    this.props.setNetworks(response);
                                });

                            dataApi.getMinMaxFilters(quoteId)
                                .then(response => {
                                    this.props.setMinMaxFilters(response);
                                });

                            favoriteApi.getListForQuote(quoteId)
                                .then(response => {
                                    this.props.setFavoriteLists(response);
                                });

                            dataApi.getDentalPlanTypesFilter(quoteId)
                                .then(response => {
                                    this.props.setPlanTypesD(response);
                                });

                            dataApi.getDentalCoinsurancesFilter(quoteId)
                                .then(response => {
                                    this.props.setCoinsurancesD(response);
                                });

                            dataApi.getDentalDeductiblesFilter(quoteId)
                                .then(response => {
                                    this.props.setDeductiblesD(response);
                                });

                            dataApi.getDentalPerioEndosFilter(quoteId)
                                .then(response => {
                                    this.props.setPerioEndosD(response);
                                });

                            dataApi.getDentalAnnualMaximumsFilter(quoteId)
                                .then(response => {
                                    this.props.setAnnualMaximumsD(response);
                                });

                            dataApi.getDentalOrthoMaximumsFilter(quoteId)
                                .then(response => {
                                    this.props.setOrthoMaximumsD(response);
                                });

                            dataApi.getDentalReimbursementsFilter(quoteId)
                                .then(response => {
                                    this.props.setReimbursementsD(response);
                                });

                            dataApi.getVisionExamCopaysFilter(quoteId)
                                .then(response => {
                                    this.props.setExamCopaysV(response);
                                });

                            dataApi.getVisionFundingTypesFilter(quoteId)
                                .then(response => {
                                    this.props.setFundingTypesV(response);
                                });

                            dataApi.getVisionMaterialCopaysFilter(quoteId)
                                .then(response => {
                                    this.props.setMaterialCopaysV(response);
                                });

                            dataApi.getVisionFrameAllowancesFilter(quoteId)
                                .then(response => {
                                    this.props.setFrameAllowancesV(response);
                                });

                            dataApi.getVisionContactLensAllowancesFilter(quoteId)
                                .then(response => {
                                    this.props.setContactLensAllowancesV(response);
                                });

                            dataApi.getVisionFrameFrequenciesFilter(quoteId)
                                .then(response => {
                                    this.props.setFrameFrequenciesV(response);
                                });

                            //If we still have Carriers that are waiting for an API response (AngleHealth, UHCLF, AFA)
                            //then we keep the loop going, but change the check-in time to 20 seconds
                            const sentToCarrier = result.filter(p => p.status === "Sent to Carrier");
                            currentCountWaitingForCarrier = sentToCarrier ? sentToCarrier.length : 0;
                            if (currentCountWaitingForCarrier > 0) {
                                waitingForCarrier = true; //set this to initialize the new loop
                                noMoreLoading = false; //reset this to keep the loop going
                                loopTime = 20000; //change loop time to 20 seconds
                                //keep the loop going
                                if (++timesRun < 45) {
                                    setTimeout(checkOnQuote, loopTime);
                                }
                            }
                        }
                        else if (noMoreLoading && waitingForCarrier && currentCountWaitingForCarrier > 0) {
                            //if we have been waiting... then compare the new count to the previous loop's
                            //count... if less, then reload the filters, which will trigger a plan reload
                            //to get any newly available carriers
                            const sentToCarrier = result.filter(p => p.status === "Sent to Carrier");
                            newCountWaitingForCarrier = sentToCarrier ? sentToCarrier.length : 0;
                            if (newCountWaitingForCarrier < currentCountWaitingForCarrier) {
                                //continue... reload data on [New Plans] tab
                                dataApi.getCarriersFilter(quoteId)
                                    .then(response => {
                                        this.props.setCarriers(response);
                                    });

                                dataApi.getMedicalPlanTypesFilter(quoteId)
                                    .then(response => {
                                        this.props.setPlanTypes(response);
                                    });

                                dataApi.getNetworksFilter(quoteId)
                                    .then(response => {
                                        this.props.setNetworks(response);
                                    });

                                dataApi.getMinMaxFilters(quoteId)
                                    .then(response => {
                                        this.props.setMinMaxFilters(response);
                                    });
                                //Add a toaster message
                                toast.success("New rates have been added to [New Plans] tab", { toastId: 1 });
                            }
                            //is the new count still greater than 0?
                            if (newCountWaitingForCarrier > 0) {
                                noMoreLoading = false; //reset this to keep the loop going
                                if (++timesRun < 45) {
                                    setTimeout(checkOnQuote, loopTime);
                                }
                            } else {
                                //no need to keep waiting... kill the loop
                                waitingForCarrier = false;
                            }
                        }
                        else {
                            if (++timesRun < 45) {
                                setTimeout(checkOnQuote, loopTime);
                            }
                        }
                    }
                });
            //search for messages
            dataApi.getQuoteMessages(quoteId)
                .then(response => {
                    this.setState({
                        messages: response,
                    });
                });
        }.bind(this);

        checkOnQuote();
    };

    _setSubStep = subStep => {
        this.props.subStepChanged(subStep);
    };

    _onContinue = () => {
        const { subStep } = this.props;
        this._setSubStep(subStep + 1);
    };

    _saveSinglePlanSpreadsheet = spreadsheet => {
        const { spreadsheets } = this.props;

        const singlePlanSpreadsheets = spreadsheets.singlePlan;

        const index = singlePlanSpreadsheets ? singlePlanSpreadsheets.findIndex(s => s.name === spreadsheet.name) : -1;

        if (index === -1) {
            singlePlanSpreadsheets.push(spreadsheet);
        } else {
            singlePlanSpreadsheets[index] = spreadsheet;
        }

        spreadsheets.singlePlan = singlePlanSpreadsheets;

        quoteProposalApi.saveSpreadsheet(this.props.quote.id, spreadsheets)
            .then(result => {
                this.props.setSpreadsheets(JSON.parse(result.spreadsheets));
            });
    };

    render() {
        const { quote, selectedPlans, spreadsheets, subStep } = this.props;
        const { processes, messages } = this.state;

        //error messages
        const problems = messages.filter(m => m.userName === "Problem").length;

        //plan counts for bubbles
        const curMedCount = quote.currentMedicalPlans && quote.currentMedicalPlans.length > 0 ? quote.currentMedicalPlans.length : 0;
        const renMedCount = quote.renewalMedicalPlans && quote.renewalMedicalPlans.length > 0 ? quote.renewalMedicalPlans.length : 0;
        const newMedCount = selectedPlans.medical && selectedPlans.medical.length > 0 ? selectedPlans.medical.length : 0;
        const curDenCount = quote.currentDentalPlans && quote.currentDentalPlans.length > 0 ? quote.currentDentalPlans.length : 0;
        const renDenCount = quote.renewalDentalPlans && quote.renewalDentalPlans.length > 0 ? quote.renewalDentalPlans.length : 0;
        const newDenCount = selectedPlans.dental && selectedPlans.dental.length > 0 ? selectedPlans.dental.length : 0;
        const curVisCount = quote.currentVisionPlans && quote.currentVisionPlans.length > 0 ? quote.currentVisionPlans.length : 0;
        const renVisCount = quote.renewalVisionPlans && quote.renewalVisionPlans.length > 0 ? quote.renewalVisionPlans.length : 0;
        const newVisCount = selectedPlans.vision && selectedPlans.vision.length > 0 ? selectedPlans.vision.length : 0;
        const curLifCount = quote.currentLifePlans && quote.currentLifePlans.length > 0 ? quote.currentLifePlans.length : 0;
        const renLifCount = quote.renewalLifePlans && quote.renewalLifePlans.length > 0 ? quote.renewalLifePlans.length : 0;
        const newLifCount = selectedPlans.life && selectedPlans.life.length > 0 ? selectedPlans.life.length : 0;
        //total counts
        const curCount = curMedCount + curDenCount + curVisCount + curLifCount;
        const renCount = renMedCount + renDenCount + renVisCount + renLifCount;
        const newCount = newMedCount + newDenCount + newVisCount + newLifCount;
        //custom spreadsheet count
        const spreadCount = spreadsheets.singlePlan.length + spreadsheets.multiPlan.length + (spreadsheets.stackedMultiPlan ? spreadsheets.stackedMultiPlan.length : 0);

        return (
            <div className={css(styles.proposalBuilderContainer)}>
                <ToastContainer position="top-center" autoClose={4000} />
                <div className={css(styles.blueBar)}>
                    <div className={css(styles.blueBarNavItem)} onClick={() => this._setSubStep(0)}>
                        <div className={css(
                            styles.blueBarText,
                            subStep === 0 ? styles.blueBarTextActive : null
                        )}>
                            {strings.proposalHome}
                            {problems > 0 &&
                                <span className={css(styles.badgeRed)}>
                                    {problems}
                                </span>
                            }
                        </div>
                    </div>
                    <div className={css(styles.blueBarNavItem)} onClick={() => this._setSubStep(1)}>
                        <div className={css(
                            styles.blueBarText,
                            subStep === 1 ? styles.blueBarTextActive : null
                        )}>
                            {strings.currentPlans}
                            {curCount > 0 &&
                                <span className={css(styles.badge)}>
                                    {curCount}
                                </span>
                            }
                        </div>
                    </div>
                    <div className={css(styles.blueBarNavItem)} onClick={() => this._setSubStep(2)}>
                        <div className={css(
                            styles.blueBarText,
                            subStep === 2 ? styles.blueBarTextActive : null
                        )}>
                            {strings.renewalPlans}
                            {renCount > 0 &&
                                <span className={css(styles.badge)}>
                                    {renCount}
                                </span>
                            }
                        </div>
                    </div>
                    <div className={css(styles.blueBarNavItem)} onClick={() => this._setSubStep(3)}>
                        <div className={css(
                            styles.blueBarText,
                            styles.blueBarTextRequired,
                            subStep === 3 ? styles.blueBarTextActive : null
                        )}>
                            {strings.newPlans}
                            {newCount > 0 &&
                                <span className={css(styles.badge)}>
                                    {newCount}
                                </span>
                            }
                            <div className={css(styles.blueBarSubText)}>{strings.required}</div>
                        </div>
                    </div>
                    <div className={css(styles.blueBarNavItem)} onClick={() => this._setSubStep(4)}>
                        <div className={css(
                            styles.blueBarText,
                            subStep === 4 ? styles.blueBarTextActive : null
                        )}>
                            Spreadsheets
                            {spreadCount > 0 &&
                                <span className={css(styles.badge)}>
                                    {spreadCount}
                                </span>
                            }
                        </div>
                    </div>
                    <div className={css(styles.blueBarNavItem)} onClick={() => this._setSubStep(5)}>
                        <div className={css(
                            styles.blueBarText,
                            styles.blueBarTextRequired,
                            subStep === 5 ? styles.blueBarTextActive : null
                        )}>
                            Output
                            <div className={css(styles.blueBarSubText)}>{strings.required}</div>
                        </div>
                    </div>
                </div>
                {subStep === 0 &&
                    <div className={css(styles.inThisSection)}>
                        <Container>
                            <Row>
                                <Column large={5} largeOffset={1}>
                                    <div className={css(styles.inThisSectionTitle)}>
                                        {strings.sectionHeaderText}
                                    </div>
                                    <div className={css(styles.sectionContainer)}>
                                        <div className={css(styles.sectionIcon)}>
                                            <PlansIcon className={css(styles.proposalBuilderIcon)} onClick={() => this._setSubStep(1)} />
                                        </div>
                                        <div className={css(styles.sectionText)}>
                                            <div className={css(styles.sectionItemTitle)}>
                                                Set up your plan information
                                            </div>
                                            <ul className={css(styles.sectionItemBullet)}>
                                                <li>Assign census members to current plans</li>
                                                <li>Map current to renewal plans</li>
                                                <li>Filter new plans by premium, plan type and more</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={css(styles.sectionContainer)}>
                                        <div className={css(styles.sectionIcon)}>
                                            <SpreadsheetIcon className={css(styles.proposalBuilderIcon)} onClick={() => this._setSubStep(4)} />
                                        </div>
                                        <div className={css(styles.sectionText)}>
                                            <div className={css(styles.sectionItemTitle)}>
                                                Create Spreadsheets
                                            </div>
                                            <ul className={css(styles.sectionItemBullet)}>
                                                <li>Single or multi-option</li>
                                                <li>Create spreadsheets in seconds</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={css(styles.sectionContainer)}>
                                        <div className={css(styles.sectionIcon)}>
                                            <ProposalDocIcon className={css(styles.proposalBuilderIcon)} onClick={() => this._setSubStep(5)} />
                                        </div>
                                        <div className={css(styles.sectionText)}>
                                            <div className={css(styles.sectionItemTitle)}>
                                                Configure your proposal output
                                            </div>
                                            <ul className={css(styles.sectionItemBullet)}>
                                                <li>Cost comparisons</li>
                                                <li>Spreadsheets</li>
                                                <li>Employee worksheets</li>
                                                <li>Underwriting guidelines</li>
                                            </ul>
                                        </div>
                                    </div>
                                </Column>
                                <Column large={5}>
                                    <div className={css(styles.proposalContainer)}>
                                        <div className={css(styles.rightSideContainer)}>
                                            <div className={css(styles.rightSideHeaderText)}>
                                                Carrier Rates
                                            </div>
                                            {processes.map((n, index) => {
                                                return (
                                                    <div key={index} className={css(styles.rightSideItem)}>
                                                        <div className={css(styles.rightSideItemCarrier)}>
                                                            {n.carrier}
                                                        </div>
                                                        <div className={css(styles.rightSideItemText)}>
                                                            {n.status === "Loading" ? (
                                                                <span className={css(styles.rightSideItemInfo)}>
                                                                    <Spinner
                                                                        aStyles={styles.loadingSpinner}
                                                                        text="Processing"
                                                                    />
                                                                </span>
                                                            ) : n.status === "Succeeded" ? (
                                                                <span className={css(styles.rightSideItemSuccess)}>{n.status}</span>
                                                            ) : n.status === "Failed" ? (
                                                                <span className={css(styles.rightSideItemErr)}>{n.status}</span>
                                                            ) : (
                                                                <span className={css(styles.rightSideItemInfo)}>{n.status}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        {messages.length > 0 &&
                                            <div className={css(styles.rightSideContainer)}>
                                                <div className={css(styles.rightSideHeaderText)}>
                                                    Notes & Messages
                                                </div>
                                                {messages.map((n, index) => {
                                                    return (
                                                        <div key={index} className={css(styles.rightSideItem)}>
                                                            <div className={css(styles.rightSideItemDate)}>
                                                                {n.createDateString}
                                                            </div>
                                                            <div className={css(styles.rightSideItemText)}>
                                                                {n.userName ? (
                                                                    <span className={css(styles.rightSideItemErr)}>{n.userName}{" - "}{n.title}{<br />}</span>
                                                                ) : (
                                                                    <span className={css(styles.rightSideItemInfo)}>{n.title}{<br />}</span>
                                                                )}
                                                                {n.description}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }
                                        {quote.proposals && quote.proposals.length > 0 &&
                                            <div className={css(styles.rightSideContainer)}>
                                                <div className={css(styles.rightSideHeaderText)}>
                                                    Proposals
                                                </div>
                                                {quote.proposals.map(p => {
                                                    return (
                                                        <div key={p.id} className={css(styles.rightSideItem)}>
                                                            <div className={css(styles.rightSideItemDate)}>
                                                                {p.createDateString}
                                                            </div>
                                                            <div className={css(styles.rightSideItemName)}>
                                                                {p.name}
                                                            </div>
                                                            {/*<div className={css(styles.rightSideItemIcon)}>
                                                            <LoadDocIcon />
                                                        </div>*/}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </div>
                                </Column>
                            </Row>
                        </Container>
                    </div>
                }

                <CurrentPlans
                    quote={this.props.quote}
                    isVisible={subStep === 1}
                    onContinue={this._onContinue}
                />

                <RenewalPlans
                    quote={this.props.quote}
                    isVisible={subStep === 2}
                    onContinue={this._onContinue}
                />

                <NewPlans
                    quote={this.props.quote}
                    isVisible={subStep === 3}
                    onContinue={this._onContinue}
                    saveSinglePlanSpreadsheet={this._saveSinglePlanSpreadsheet}
                />
                <div className={css(subStep !== 4 ? styles.hide : null)}>
                    <Spreadsheets
                        setSubStep={this._setSubStep}
                    />
                </div>

                <div className={css(subStep !== 5 ? styles.hide : null)}>
                    <Output />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = state => ({
    quote: state.proposal.quote,
    selectedPlans: state.proposal.selectedPlans,
    spreadsheets: state.proposal.spreadsheets,
});

export default connect(
    mapDispatchToProps, {
    setSelectedPlans: proposalActions.setSelectedPlans,
    setSpreadsheets: proposalActions.setSpreadsheets,
    setCarriers: proposalActions.setCarriers,
    setPlanTypes: proposalActions.setPlanTypes,
    setNetworks: proposalActions.setNetworks,
    setMinMaxFilters: proposalActions.setMinMaxFilters,
    setFavoriteLists: proposalActions.setFavoriteLists,
    setPlanTypesD: proposalActions.setPlanTypesD,
    setCoinsurancesD: proposalActions.setCoinsurancesD,
    setDeductiblesD: proposalActions.setDeductiblesD,
    setPerioEndosD: proposalActions.setPerioEndosD,
    setAnnualMaximumsD: proposalActions.setAnnualMaximumsD,
    setOrthoMaximumsD: proposalActions.setOrthoMaximumsD,
    setReimbursementsD: proposalActions.setReimbursementsD,
    setExamCopaysV: proposalActions.setExamCopaysV,
    setFundingTypesV: proposalActions.setFundingTypesV,
    setMaterialCopaysV: proposalActions.setMaterialCopaysV,
    setFrameAllowancesV: proposalActions.setFrameAllowancesV,
    setContactLensAllowancesV: proposalActions.setContactLensAllowancesV,
    setFrameFrequenciesV: proposalActions.setFrameFrequenciesV,
},
)(Landing);
