import instance from "./instance";

const responseData = response => response.data;

export function getAll() {
    return instance
        .request({
            url: "/quote/get-quotes",
            method: "GET",
        })
        .then(responseData);
}


export function getPaged(page, sortColumn, sortDirection, showAll, testOnly, areaId, effectiveMonth, year, createdById, salesRepId, status) {
    return instance
        .request({
            url: "/quote/get-quotes-page",
            method: "POST",
            data: {
                page,
                sortColumn,
                sortDirection,
                showAll,
                testOnly,
                areaId,
                effectiveMonth,
                year,
                createdById,
                salesRepId,
                status
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function searchQuotes(term) {
    term = term.replace(/[^0-9a-zA-Z\s]+/g, "");
    if (term.length === 0) { term = "*"; }
    return instance
        .request({
            url: `/quote/search-quotes/${term}`,
            method: "GET",
        })
        .then(responseData);
}

export function getById(id) {
    return instance
        .request({
            url: `/quote/get-quote/${id}`,
            method: "GET",
        })
        .then(responseData);
}

export function saveQuote(quote) {
    return instance
        .request({
            url: "/quote/save-quote",
            method: "POST",
            data: { ...quote },
        })
        .then(responseData);
}

export function saveCurrentRenewal(quoteId, id, lob, add, isCurrent, isBase) {
    return instance
        .request({
            url: "/quote/save-quote-current-renewal",
            method: "POST",
            data: {
                quoteId: quoteId,
                planIds: [id],
                lob: lob,
                add: add,
                isCurrent: isCurrent,
                isBase: isBase
            },
        })
        .then(responseData);
}

export function saveMultipleCurrentRenewal(quoteId, planIds, lob, add, isCurrent, isBase) {
    return instance
        .request({
            url: "/quote/save-quote-current-renewal",
            method: "POST",
            data: {
                quoteId: quoteId,
                planIds: planIds,
                lob: lob,
                add: add,
                isCurrent: isCurrent,
                isBase: isBase
            },
        })
        .then(responseData);
}

export function savePlanRates(quoteId, medicalPlanRates, dentalPlanRates, visionPlanRates) {
    return instance
        .request({
            url: "/quote/save-plan-rates",
            method: "POST",
            data: {
                quoteId: quoteId,
                medicalPlanRates: medicalPlanRates,
                dentalPlanRates: dentalPlanRates,
                visionPlanRates: visionPlanRates
            },
        })
        .then(responseData);
}

export function saveCurrentRenewalCompositeRates(quoteId, lob, medicalPlans, dentalPlans, visionPlans) {
    return instance
        .request({
            url: "/quote/save-quote-current-renewal-composite-rates",
            method: "POST",
            data: {
                quoteId: quoteId,
                planIds: [],
                lob: lob,
                add: false,
                isCurrent: true,
                isBase: false,
                currentMedicalPlans: medicalPlans,
                currentDentalPlans: dentalPlans,
                currentVisionPlans: visionPlans
            },
        })
        .then(responseData);
}

export function submitQuote(quoteId) {
    return instance
        .request({
            url: `/quote/submit-quote/${quoteId}`,
            method: "POST",
        })
        .then(responseData);
}

export function isQuoteReady(quoteId) {
    return instance
        .request({
            url: `/quote/is-quote-ready/${quoteId}`,
            method: "POST",
        })
        .then(responseData);
}

export function getQuoteDetails(quoteId) {
    return instance
        .request({
            url: `/quote/quote-details/${quoteId}`,
            method: "GET",
        })
        .then(responseData);
}

export function updateQuoteStatus(quoteId, quoteStatusId) {
    return instance
        .request({
            url: "/quote/update-quote-status",
            method: "POST",
            data: {
                quoteId,
                quoteStatusId,
            },
        })
        .then(responseData);
}

export function deleteQuote(quoteId) {
    return instance
        .request({
            url: `/quote/delete-quote/${quoteId}`,
            method: "POST",
        })
        .then(responseData);
}

export function saveManualCompositeRates(quoteId, lob, planId, mthlyRate, eeRate, esRate, ecRate, efRate) {
    return instance
        .request({
            url: "/quote/save-manual-composite-rates",
            method: "POST",
            data: {
                quoteId,
                lob,
                planId,
                mthlyRate,
                eeRate,
                esRate,
                ecRate,
                efRate,
            },
        })
        .then(responseData);
}

export function getAgeRated(quoteId, selectedPlanIds) {
    return instance
        .request({
            url: "/quote/get-age-rates",
            method: "POST",
            data: {
                quoteId,
                selectedPlanIds
            },
        })
        .then(responseData);
}

export function saveNote(quoteNote) {
    return instance
        .request({
            url: "/quote/saveNote",
            method: "POST",
            data: { ...quoteNote },
        })
        .then(responseData);
}

export function getNotes(quoteId) {
    return instance
        .request({
            url: `/quote/getNotes/${quoteId}`,
            method: "GET",
        })
        .then(responseData);
}

export function saveSelectedNewPlans(quoteId, medicalPlans, dentalPlans, visionPlans, lifePlans) {
    return instance
        .request({
            url: "/quote/save-quote-new-plans",
            method: "POST",
            data: {
                quoteId: quoteId,
                medicalPlans: medicalPlans,
                dentalPlans: dentalPlans,
                visionPlans: visionPlans,
                lifePlans: lifePlans
            },
        })
        .then(responseData);
}
